/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import dynamic from "next/dynamic";
import Script from "next/script";
import Router, { useRouter } from "next/router";
import localFont from "next/font/local";
import { Lato, Titillium_Web } from "next/font/google";

import { Provider } from "react-redux";
import { createWrapper } from "next-redux-wrapper";
import store from "@/redux/store";

//import utils and helpers
import { useEffectOnce } from "@/hooks/useEffectCustom";
import { pageview } from "@/lib/gtm";

const isServer = typeof window === "undefined";

// Import master component
const Master = dynamic(() => import("@/components/Layout/Master"), { ssr: true });

import { fetcher, getPreviewRedirectURL } from "@/utils/functions";

import { PREVIEW_LINK_QUERY } from "@/graphql/settingsQuery";

// Import style CSS
import "@/styles/tailwind.css";
import "aos/dist/aos.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
// import "bootstrap/dist/css/bootstrap.min.css";

const lato = Lato({
  weight: ["400", "700"],
  subsets: ["latin"],
  display: "swap",
  variable: "--font-lato",
});

const titillium = Titillium_Web({
  weight: ["300", "400", "600", "700"],
  subsets: ["latin"],
  display: "swap",
  variable: "--font-titillium",
});

const optiJakeRegular = localFont({
  src: "../../public/fonts/OPTIJakeRegular/OPTIJakeRegular.otf",
  display: "swap",
  variable: "--font-optiJake-regular",
});

const optiJakeMedium = localFont({
  src: "../../public/fonts/OPTIJakeMedium/OPTIJakeMedium.ttf",
  display: "swap",
  variable: "--font-optiJake-medium",
});

function MyApp({ Component, pageProps }) {
  const [isPageLoad, setPageLoad] = useState(false);

  // log.debug("Dummy log", { Loading: loading });

  const router = useRouter();
  const { asPath, pathname, route, locale } = router;

  const notifyPageView = () => {
    pageview(pathname, locale);
  };

  // done specifically to disable cookies of any form on this page
  // https://linear.app/xpitality/issue/XPT-6105/wi-fi-landing-page-should-be-made-cookieless
  const shouldLoadCookies = !asPath.includes("/landing/wifi");

  useEffect(() => {
    typeof document !== "undefined" ? require("bootstrap/dist/js/bootstrap.bundle.min.js") : null;
    // import("jquery/dist/jquery.min");
    // import("bootstrap/js/dist/dropdown");
  }, []);

  useEffect(() => {
    // Used for exit preview mode when open using live URL
    const exitPreviewMode = async () => {
      if (asPath?.indexOf("preview") > -1) {
        const res = await fetch("/api/exit-preview/?", {
          method: "GET",
        });
        if (res) {
          let slug = asPath?.substring(0, asPath.indexOf("?"))?.split("/")?.pop();
          let query = PREVIEW_LINK_QUERY?.replace(/\lang/g, locale);
          const slugResponse = await fetcher(query, true);
          let path = getPreviewRedirectURL(slug, slugResponse);
          await Router.push(path.toLowerCase(), path.toLowerCase(), {
            locale: locale,
          });
        }
      }
      setPageLoad(true);
    };

    exitPreviewMode();

    Router.events.on("routeChangeComplete", notifyPageView);
    Router.events.on("routeChangeError", notifyPageView);
    return () => {
      Router.events.off("routeChangeComplete", notifyPageView);
      Router.events.off("routeChangeError", notifyPageView);
    };
  }, [asPath, locale, pathname, route]);

  // using custom useEffect
  useEffectOnce(() => {
    if (locale && !isServer) {
      // this is primarily a hack to enable page tracking on first load only
      // as router events do not track first load
      notifyPageView();
    }
  }, [locale, isServer]);

  return (
    <>
      {shouldLoadCookies && (
        <Script
          id="gtag-base"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer', '${process.env.GOOGLE_TAG_MANAGER_ID}');
        `,
          }}
        />
      )}

      {isPageLoad && (
        <Provider store={store}>
          <main
            className={`${titillium.variable} ${lato.variable} ${optiJakeRegular.variable} ${optiJakeMedium.variable} font-sans`}
          >
            <Master shouldLoadCookies={shouldLoadCookies}>
              <Component {...pageProps} />
            </Master>
          </main>
        </Provider>
      )}
    </>
  );
}

const makeStore = () => store;

const wrapper = createWrapper(makeStore);

export default wrapper.withRedux(MyApp);
